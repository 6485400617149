<template>
    <b-form>
        <b-row>
            <b-col md="12">
                <b-form-group label="Name">
                    <b-form-input
                        id="name"
                        v-model="name"
                        placeholder="Name"
                        required
                        name="name"
                        maxlength="250"
                        :state="validateName"
                    />
                    <b-form-invalid-feedback
                        v-show="!validateName"
                        id="name-feedback"
                    >
                        Name is Required
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>

            <b-col md="12">
                <b-card>
                    <b-form-group label="Template">
                        <b-form-select
                            v-model="currentVariable"
                            text-field="name"
                            value-field="id"
                            :options="variables"
                            placeholder="Choose Variable"
                            class="mb-2"
                            @input="variableSelected"
                        />
                        <b-form-textarea
                            id="template"
                            ref="templateArea"
                            v-model="template"
                            placeholder="Template"
                            required
                            name="template"
                            :state="validateTemplate"
                        />
                        <b-form-invalid-feedback
                            v-show="!validateTemplate"
                            id="template-feedback"
                        >
                            Template is Required
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
        <b-button
            variant="primary"
            :disabled="submitDisabled"
            @click="onSubmit"
        >
            Submit
        </b-button>
    </b-form>
</template>
<script>
import {
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    BFormGroup,
    BCard,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        BForm,
        BRow,
        BCol,
        BFormInput,
        BFormTextarea,
        BFormSelect,
        BButton,
        BFormGroup,
        BCard,
    },
    data() {
        return {
            name: '',
            template: '',
            currentVariable: null,
            variables: [
                { id: null, name: 'Choose Variables from here' },
                {
                    id: '{$experience_name}',
                    name: 'Experience Name',
                },
                { id: '{$experience_date}', name: 'Experience Date' },
                {
                    id: '{$experience_start_time}',
                    name: 'Experience Start Time',
                },
                { id: '{$experience_end_time}', name: 'Experience End Time' },
                { id: '{$member_first_name}', name: 'First Name of Member' },
                { id: '{$member_last_name}', name: 'Last Name of Member' },
            ],
            id: 0,
            url: '',
            module: '',
        };
    },
    computed: {
        validateName() {
            return this.name.length > 0;
        },
        validateTemplate() {
            return this.template.length > 0;
        },
        submitDisabled() {
            return !(this.validateName && this.validateTemplate);
        },
    },
    created() {
        this.module = 'notificationtemplates';
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.url = `/${this.module}/view/${this.id}`;
        } else {
            this.url = `/${this.module}/createView`;
        }
        this.getCreateData();
    },

    methods: {
        async getCreateData() {
            if (this.id !== 0) {
                let response = await this.$axios.get(this.url).catch(error => {
                    console.error(error);
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
                response = response.data;
                if (response.results.result) {
                    this.name = response.results.result.name;
                    this.template = response.results.result.template;
                }
            }
        },
        async onSubmit() {
            let url = `/${this.module}/save`;
            if (this.id !== 0) {
                url = `/${this.module}/update/${this.id}`;
            }
            const response = await this.$axios
                .post(url, {
                    name: this.name,
                    template: this.template,
                })
                .catch(error => {
                    console.error(error);
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
            if (
                this.id === 0
                && response.data.results
                && response.data.results.id > 0
            ) {
                this.$router.push(`/account/${this.module}`).catch(() => {});
            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Record Updated',
                        icon: 'CheckIcon',
                        variant: 'primary',
                    },
                });
            }
        },
        variableSelected(value) {
            const textarea = this.$refs.templateArea;
            const cursorPosition = textarea.selectionStart;
            this.template = this.template.substr(0, cursorPosition)
                + value
                + this.template.substr(cursorPosition, this.template.length);
            textarea.focus();
        },
    },
};
</script>
